import SyfAjax from './SyfAjax';
import config from '../configuration/configuration';
export default function SyfUILogger() {
    var self = this;
    this.syfAjax = new SyfAjax();
    this.userAgentString = self.getBrowserInfo();
    this.errorLogURL = config[config.env].syfDomain + "DigitalBuy/exceptionLog.do";
    this.tokenID = null;
}
SyfUILogger.prototype = {
    constructor: SyfUILogger,
    sendLogs: function (errorLog) {
        var formBody = [];
        formBody.push(encodeURIComponent('userAgent') + "="
            + encodeURIComponent(this.userAgentString));
        formBody.push(encodeURIComponent('resolution') + "="
            + encodeURIComponent(this.getResolutionInfo()));
        formBody.push(encodeURIComponent('desc')
            + "="
            + encodeURIComponent(typeof errorLog == 'object'
                && Array.isArray(errorLog) == false ? JSON
                    .stringify(errorLog) : errorLog));
        formBody.push(encodeURIComponent('tokenID') + "="
            + encodeURIComponent(this.tokenID));
        formBody = formBody.join("&");
        this.syfAjax.post(this.errorLogURL, {
            "Content-Type": "application/x-www-form-urlencoded"
        }, formBody, function (err, data) {
            // do nothing on error log sending failure
        });
    },
    getBrowserInfo: function () {
        return navigator.userAgent;
    },
    getResolutionInfo: function () {
        return "{availableHeight=" + window.screen.availHeight
            + "availableWidth=" + window.screen.availWidth
            + "currentHeight=" + window.innerHeight + "currentWidth="
            + window.innerWidth + "}"
    }
}