import MPPContainer from '../mpp-container/container';
import SyfAjax from '../Util/SyfAjax';
import SyfUILogger from '../Util/SyfUILogger';
import { isNotEmpty, createInputField, getGenericMPPModalTemplate, getSeamlessApplyBuyCookie } from '../Util/CommonUtils';
import config from '../configuration/configuration';
class SetPayIntegeration extends MPPContainer {
    constructor(analytics) {
        super(analytics);
        this.syfUILogger = new SyfUILogger();
        this.merchantData = null;
        // this.registerDBUYEventListener(this);
    }
    callInstallmentProcess = (formObj) => {
        try {
            var merchantId = document.getElementById("merchantID").value;
            var tokenId = document.getElementById("tokenId").value;
            this.fetchInstallmentInfo(merchantId, tokenId, (err, merchantData) => {
                this.merchantData = JSON.parse(merchantData);
                this.openInstallmentModalDialog(formObj, JSON.parse(merchantData));
            });
        } catch (exception) {
            this.syfUILogger.sendLogs(exception);
        }
    };
    fetchInstallmentInfo = (merchantId, tokenId, callback) => {
        let syfAjax = new SyfAjax();
        syfAjax.get(config[config.env].syfDomain + config[config.env].syfFetchMerchantURL + "?merchantId=" + merchantId
            + "&tokenId=" + tokenId, "", (err, data) => {
                if (err) {
                    this.syfUILogger.sendLogs(err);
                    callback(err, null);
                } else if (isNotEmpty(data)) {
                    callback(null, JSON.parse(JSON.stringify(data.responseText)));
                } else {
                    callback(null, null);
                }
                syfAjax = null;
            });
    }
    mppModalElementTemplate = () => {
        return getGenericMPPModalTemplate();
    }
    modalActionOnDOMAttach = (formObj, url) => {
        var inputField = createInputField("hidden", "channelId", "channelId");
        inputField.value = "DY";
        formObj.appendChild(inputField);

        //mppcore server reads this url and redirect it to the given url

        var inputFieldInstallmentURL = createInputField("hidden", "installmentUrl", "installmentUrl");
        inputFieldInstallmentURL.value = url;
        formObj.appendChild(inputFieldInstallmentURL);


        var form = formObj;
        if (form == null || form == 'undefined' || form == 'Undefined') {
            form = document.getElementById('dbuyform1');
        }
       
        var visitor = window.Visitor.getInstance('22602B6956FAB4777F000101@AdobeOrg');
        var destinationURLWithVisitorIDs = visitor.appendVisitorIDsTo(config[config.env].syfMPPModalURL['checkout']);
        
        form.target = "mppFrame";
        form.action = destinationURLWithVisitorIDs;
        form.method = "POST";
        form.submit();

        

    }
    openInstallmentModalDialog = (formObj, merchantData) => {
        let url = null;
        getSeamlessApplyBuyCookie(formObj.merchantID.value,(syfToken)=>{
            if (merchantData.tokenActive) {
                url = merchantData.installmentUrl;
                if (isNotEmpty(syfToken)) {
                    url = url + "?syfToken=" + syfToken;
                }
            } else {
                url = merchantData.installmentExpiredUrl;
            }
            if (document.getElementById('mppModal') != null) {
                try {
                    document.getElementById('mppModal').remove();
                } catch (e) {
                    document.getElementById('mppModal').parentNode.removeChild();
                }
            }
            let modalElementId = config[config.env].syfMppModalHolderId;

            if (!document.getElementById(modalElementId)) {
                let modalDiv = document.createElement("div");
                modalDiv.id = modalElementId;
                document.body.appendChild(modalDiv);
            }
            let modalTemplate = this.mppModalElementTemplate();
            this.loadModalInDOM(modalElementId, modalTemplate);


            this.modalActionOnDOMAttach(formObj, url);

                   /* setTimeout(
            function () {
                if (window.matchMedia('(max-width: 767px)').matches) {
                    var res = navigator.userAgent.toLowerCase().indexOf(
                        "iphone");
                    if (res > -1) {
                        document.getElementById('mppModal').style.cssText = "position: absolute !important; min-height: 550px !important;";
                        document.getElementById('mppModal').style.bottom = 'initial';
                    }
                }

                if (window.matchMedia('(min-width: 320px)').matches
                    && window.matchMedia('(max-width: 480px)').matches) {
                    document.getElementById('mppFrame').style.height = Math
                        .round(window.innerHeight
                            - (window.innerHeight * 8 / 100))
                        + 'px';

                } else if (window.matchMedia('(min-width: 480px)').matches
                    && window.matchMedia('(max-width: 767px)').matches) {
                    document.getElementById('mppFrame').style.height = Math
                        .round(window.innerHeight
                            - (window.innerHeight * 8 / 100))
                        + 'px';

                } else {
                    document.getElementById('mppFrame').style.height = Math
                        .round(window.innerHeight
                            - (window.innerHeight * 8 / 100))
                        + 'px';
                }
            }, 50); */
        //Remove close button for Setpay @Reqment for pilot
        document.getElementById("syfMPPCrossButton").remove();
        });
   
    };

}
export default SetPayIntegeration;
