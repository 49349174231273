const dbuyGenericFields = {

    processInd: { type: "string" },
    tokenId: { type: "string" },
    transactionToken: { type: "string" },
    syfPartnerId : { type: "string" },
    clientTransId: { type: "string" },
    custFirstName: { type: "string" },
    custLastName: { type: "string" },
    custZipCode: { type: "string" },
    cardNumber: { type: "string" },
    expMonth: { type: "string" },
    expYear: { type: "string" },
    iniPurAmt: { type: "string" },
    custAddress1: { type: "string" },
    custAddress2: { type: "string" },
    phoneNumber: { type: "string" },
    emailAddress: { type: "string" },
    custCity: { type: "string" },
    custState: { type: "string" },
    upeProgramName: { type: "string" },
    transAmount1: { type: "string" },
    pcgc: { type: "string" },

};
export default dbuyGenericFields;