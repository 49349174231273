export default function SyfAjax() {
	this.timeout = 20000;
	this.callback = null;
	this.xhr = new XMLHttpRequest();
	var self = this;

	// Register handlers for xhr
	this.xhr.ontimeout = function () {
		return self.xhrTimeout(this, self.callback);
	};
	this.xhr.onerror = function () {
		return self.xhrError(this, self.callback);
	};
	this.xhr.onload = function () {
		return self.xhrSuccess(this, self.callback);
	}
}
SyfAjax.prototype = {
	constructor : SyfAjax,
	xhrSuccess : function(xhr, callback) {
		if (xhr.readyState === 4) {
			callback(null, xhr);
		}
	},
	xhrError : function(xhr, callback) {
		callback(xhr, null);
	},
	xhrTimeout : function(xhr, callback) {
		callback(xhr, null);
	},
	processRequestHeader : function(xhr, header, callback) {
		if (header !== undefined && header !== null
				&& typeof header === "object"
				&& Array.isArray(header) === false) {
			var headerAttributes = Object.keys(header);
			headerAttributes.forEach(function(attr) {
				xhr.setRequestHeader(attr, header[attr]);
			});
			callback();
		} else {
			callback();
		}
	},
	get : function(url, header, callback) {
		this.callback = callback;
		this.xhr.open('GET', url + ((/\?/).test(url) ? "&" : "?")
				+ (new Date()).getTime(), true);
		this.xhr.timeout = this.timeout;
		var self = this;
		this.processRequestHeader(this.xhr, header, function() {
			self.xhr.send(null);
		});
	},
	post : function(url, header, data, callback) {
		this.callback = callback;
		this.xhr.open('POST', url, true);
		this.xhr.timeout = this.timeout;
		var self = this;
		this.processRequestHeader(this.xhr, header, function() {
			self.xhr.send(data);
		});
	}
}