import config from "../configuration/configuration";
import { isEventSourceSYF } from "../Util/CommonUtils";
import SyfAjax from "../Util/SyfAjax";
import services from '../../../src/services/Services';
class MPPContainer {
  constructor(analytics) {
    this.registerCommonEventListener();
    this.isChangeCloseButtonPositionRequested = false;
    this.analytics = analytics;
    this.windowObject = window;
    this.tokenId = null;
    this.flowType = "";
    this.partnerId = "";
    this.syf_Token = "";
  }
  commonEventListener = (event) => {
    if (isEventSourceSYF(event.origin)) {
      if (typeof event.data == "object") {
        if (event.data.action !== undefined) {
          if (event.data.action === "applyCloseModal") {
            this.closeModalWindow();
          } else if (event.data.action === "setPayCloseModal") {
            this.destroySyfToken();
            this.closeModalWindow();
            this.unifyCloseModalEvent();
            console.log("In return-to-partner");
          }
        }
        if (event.data.event === "return-to-partner") {
          this.destroySyfToken();
          this.closeModalWindow();
          this.unifyCloseModalEvent();
          console.log("return-button clicked " + event.data.event);
        }
        if (event.data.event === "return-to-source") {
          if (event.data.applicationStatus === "INPROGRESS") {
            this.closeModalWindow();
          } else {
            this.destroySyfToken();
            this.closeModalWindow();
            this.unifyCloseModalEvent();
            console.log("return-button clicked " + event.data.event);
          }
        }
        if (event.data._isCloseEventCallBack) {
          window._isCloseEventCallBack = event.data._isCloseEventCallBack;
        }
      } else if (typeof event.data == "string") {
        if (event.data === "Close") {
          this.closeModalWindow();
        } else if (event.data === "closeBtnFocus") {
          document.getElementById("syfMPPCrossButton").focus();
        } else if (event.data === "changeCloseBtnPosition") {
          this.isChangeCloseButtonPositionRequested = true;
        } else if (event.data === "HideCloseButton") {
          document.getElementById("syfMPPCrossButton").remove();
        } else if (event.data === "destroy-syf-session") {
          this.destroySyfToken();
        } else if (event.data == "removeMerchantAuthToken") {
          this.removeMerchantToken();
        }
      }
    }
  };
  registerCommonEventListener() {
    window.addEventListener("message", this.commonEventListener, false);
  }
  unregisterCommonEventListener() {
    window.removeEventListener("message", this.commonEventListener, false);
  }
  mppContainerLoadEvent = () => {
    //hide spinner
    document.getElementById("iframeMPPloading").style.display = "none";
    //height adjustment for mobile devices
    if (window.matchMedia("(max-width: 767px)").matches) {
      document.getElementsByClassName("syf-mpp-modal-open")[0].style.position =
        "fixed";
    }
    //focus close button on modal open
    if (document.getElementById("syfMPPCrossButton") != null) {
      document.getElementById("syfMPPCrossButton").focus();
      document
        .getElementById("syfMPPCrossButton")
        .addEventListener("keydown", function (event) {
          if (event.shiftKey && event.key === "Tab") {
            event.preventDefault();
          }
        });
    }
    if (this.isChangeCloseButtonPositionRequested) {
      this.changeCloseBtnPosition();
    }
  };
  loadModalInDOM = (modalId, modalTemplate) => {
    document.getElementById(modalId).innerHTML = modalTemplate;

    //Show Spinner before loading
    document
      .getElementById("syfMPPLoadingImage")
      .setAttribute(
        "src",
        config[config.env].syfDomain + config[config.env].syfLoadingImageURL
      );
    document.getElementById("iframeMPPloading").style.display = "block";
    //Disable Spinner on Load + Adjust height of the Window for Mobile Devices on load
    document.body.classList.add("syf-mpp-modal-open");
    document.getElementById("mppFrame").onload = this.mppContainerLoadEvent;
  };
  closeModalWindow = () => {
    //Refresh Widget
    this.windowObject.postMessage(
      JSON.stringify({ event: "widget-content-refresh" }),
      "*"
    );
    this.analytics.fireAnalyticsEvent(this.analytics.destroy);
    document.getElementById("mppModal").style.display = "none";
    document.getElementsByClassName("syf-mpp-modal-open")[0].style.position =
      "relative";
    document.body.classList.remove("syf-mpp-modal-open");
    this.unregisterCommonEventListener();
    if (window._isCloseEventCallBack === "Y") {
      this.storingFetchStatusResponse(this.flowType);
    }
  };
  closeModalWithKeyboard(event) {
    let key = event.which || event.keyCode;
    if (key === 13) {
      // enter pressed then invoke the validation method
      this.closeModalWindow();
    }
  }
  changeCloseBtnPosition = () => {
    document
      .getElementsByClassName("syf-mpp-chng-height")[0]
      .classList.add("syf-mpp-titleBar");
    document
      .getElementById("syfMPPModalCloseBtn")
      .setAttribute("class", "syf-mpp-modal-XImage");
    this.isChangeCloseButtonPositionRequested = false;
  };
  destroySyfToken = () => {
    let expirationDateTime = new Date();
    expirationDateTime.setTime(expirationDateTime.getTime());
    document.cookie =
      "syfToken=; Path=/; Expires=" + expirationDateTime.toUTCString();
  };
  unifyCloseModalEvent = () => {
    window.postMessage("Unifi Modal Close", "*");
  };
  removeMerchantToken = () => {
    let syfAjax = new SyfAjax();
    syfAjax.get(
      config[config.env].syfTokenRemoveUrl + this.tokenId,
      "",
      (err, data) => {
        if (data) {
          let response = JSON.parse(data.responseText);
          window.postMessage("Close", "*");
        } else if (err || data.status === 400) {
          let logDesc = err;
          window.postMessage("Close", "*");
          if (null != data && data.status === 400) {
            logDesc = "Failed 400 " + data.responseURL;
          }
          this.syfUILogger.sendLogs(logDesc);
        }
        syfAjax = null;
      }
    );
  };
  storingFetchStatusResponse = (flowType) => {
    setTimeout(() => {
      services.fetchStatus(this.syf_Token, this.partnerId)
        .then((response) => {
          if (response) {
            window._SYF_status = response;
            if (flowType === "PDP") {
              window.postMessage("PDP Modal Close", "*");
            }
            this.unifyCloseModalEvent();
          }
        })
        .catch((error) => {
          return error;
        });
    }, 500)
  }

}
export default MPPContainer;
