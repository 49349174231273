import "./assets/syfMPPModalStyles.css";
import DigitalBuyIntegartion from "./dbuy-integration/DigitalBuyIntegartion";
import SetPayIntegeration from "./setpay-integration/SetPay-Integration";
import OfferIntegration from "./offer-integration/OfferIntegration";
import config from "./configuration/configuration";
import JSONValidator from "ajv";
import OfferInputSchema from "./offer-integration/OfferInputValidationSchema";
import DBUYModal1Schema from "./dbuy-integration/Modal1ValidationSchema";
import DBUYModal2Schema from "./dbuy-integration/Modal2ValidationSchema";
import DBUYCombinedModal from "./dbuy-integration/CombinedModalValidation";
import { getSeamlessApplyBuyCookie, getSessionCookieAndSyfToken, populateSyfTokenCookie, setSessionCookie } from "./Util/CommonUtils";

import {
  formToJSON,
  getMPPScriptElementOnMissingId,
  startLazyLoaderMatrix,
  waitAndExecute,
} from "./Util/CommonUtils";
import Analytics from "./analytics/Analytics";
import { initial } from "lodash";

function attachCSSToDOM() {
  let cssId = "syf-mpp-css";
  var head = document.getElementsByTagName("head")[0];
  if (!document.getElementById(cssId)) {
    var link = document.createElement("link");
    link.id = cssId;
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href =
      config[config.env].syfMPPURL + config[config.env].syfCssFileLocation;
    link.media = "all";
    head.appendChild(link);
  }

}

function getEnvironmentDetails(callback) {
  window["__syfMPP__"]["matrix"]["UniFi"]["ENV_CONFIG"]["start"] = Date.now();
  let scriptElement = document.getElementById("syfMPPScript");
  scriptElement =
    scriptElement === null ? getMPPScriptElementOnMissingId() : scriptElement;
  let scriptHost;
  let envURL;
  if (!scriptElement) {
    console.error("Integration Script Missing.Id should be matched.");
  } else {
    scriptHost = scriptElement.src;
    envURL = scriptHost.substring(0, scriptHost.lastIndexOf("/"));
    envURL = envURL + "/env-config.js";
  }

  let script = document.createElement("script");
  script.src = envURL;
  document.getElementsByTagName("head")[0].appendChild(script);

  script.onload = function () {
    config.env = window._syfMpp_.SYF_MPP_ENV;
    window["__syfMPP__"]["matrix"]["UniFi"]["ENV_CONFIG"][
      "isLoadComplete"
    ] = true;
    window["__syfMPP__"]["matrix"]["UniFi"]["ENV_CONFIG"]["end"] = Date.now();
    callback();
  };
}
function loadWidgetToDOM() {
  addDNSPreFetchInHeader("dns-prefetch");
  addDNSPreFetchInHeader("preconnect");
  //import widget if not widget="disabled"
  let element = document.getElementById("syfMPPScript");
  element = element === null ? getMPPScriptElementOnMissingId() : element;
  if (
    element != null &&
    element.hasAttribute("widget") &&
    element.getAttribute("widget") === "disabled"
  ) {
    //destroy widget if already in scope--- future ref
  } else {
    let envURL = config[config.env].widgeturl;
    let productcategoryNames = "";
    let offerNumber = "";
    let prodAttributes = "";
    let subFlowType = "";
    let syfWidgetObjectUnifi = getSyfWidgetObject();
    let encodedProdAttributes = "";
    let syf_page_name = "";
    let syfPlatform = "";

    if (syfWidgetObjectUnifi.flowType === "PDP" || syfWidgetObjectUnifi.flowType === "CART") {
      syf_page_name = "/syf-widget.js";
    } else if (syfWidgetObjectUnifi.flowType === "MULTIWIDGET") {
      syf_page_name = "/syf-multi-widget.js";
    } else if (syfWidgetObjectUnifi.processInd === "3" ||
      syfWidgetObjectUnifi.processInd === "2" ||
      syfWidgetObjectUnifi.flowType === "CHECKOUT") {
      syf_page_name = "/syf-checkout-widget.js";
    }

    if (
      syfWidgetObjectUnifi.tags != "undefined" &&
      syfWidgetObjectUnifi.tags != null
    ) {
      productcategoryNames = syfWidgetObjectUnifi.tags;
    }
    if (
      syfWidgetObjectUnifi.offerNumber1 != "undefined" &&
      syfWidgetObjectUnifi.offerNumber1 != null
    ) {
      offerNumber = syfWidgetObjectUnifi.offerNumber1;
    }

    if (
      syfWidgetObjectUnifi.subFlowType != "undefined" &&
      syfWidgetObjectUnifi.subFlowType != null
    ) {
      subFlowType = syfWidgetObjectUnifi.subFlowType;
    }

    if (syfWidgetObjectUnifi.productAttributes) {
      prodAttributes = syfWidgetObjectUnifi.productAttributes;
      //Encoding the ProductAttributes (Json format to base64)
      // let parsedProductAttributes = JSON.parse(prodAttributes);
      encodedProdAttributes = tagJsonToString(prodAttributes)
    }
    if (
      syfWidgetObjectUnifi.syfPlatform != "undefined" &&
      syfWidgetObjectUnifi.syfPlatform != null
    ) {
      syfPlatform = syfWidgetObjectUnifi.syfPlatform;
    }

    if (
      syfWidgetObjectUnifi.syfPartnerId === "undefined" ||
      syfWidgetObjectUnifi.syfPartnerId == null
    ) {
      console.warn("Widget is Disabled. partnerId is mandatory");
    } else {
      let widgetScriptURL =
        envURL +
        syf_page_name + "?partnerId=" + syfWidgetObjectUnifi.syfPartnerId +
        "&productCategoryNames=" +
        String(productcategoryNames) +
        "&offerNumber=" +
        String(offerNumber) +
        "&processInd=" +
        syfWidgetObjectUnifi.processInd +
        "&cid=unifitest" +
        "&syfPlatform=" + syfPlatform;
      if (syfWidgetObjectUnifi.childSyfPartnerId) {
        widgetScriptURL =
          widgetScriptURL + "&childSyfPartnerId=" + syfWidgetObjectUnifi.childSyfPartnerId;
      }
      if (syfWidgetObjectUnifi.childMid) {
        widgetScriptURL =
          widgetScriptURL + "&childMid=" + syfWidgetObjectUnifi.childMid;
      }
      if (syfWidgetObjectUnifi.partnerCode) {
        widgetScriptURL =
          widgetScriptURL + "&partnerCode=" + syfWidgetObjectUnifi.partnerCode;
      }
      if (syfWidgetObjectUnifi.childSyfMerchantNumber) {
        widgetScriptURL =
          widgetScriptURL + "&childSyfMerchantNumber=" + syfWidgetObjectUnifi.childSyfMerchantNumber;
      }
      console.log("syfWidgetObject promooverrideflag" + syfWidgetObjectUnifi.promoOverRideFlag);
      if (syfWidgetObjectUnifi.promoOverRideFlag && syfWidgetObjectUnifi.promoOverRideFlag === "Y") {
        widgetScriptURL =
          widgetScriptURL + "&promoOverRideFlag=" + syfWidgetObjectUnifi.promoOverRideFlag;
      }
      if (encodedProdAttributes !== "") {
        widgetScriptURL =
          widgetScriptURL + "&productAttributes=" + encodedProdAttributes;
      }
      if (syfWidgetObjectUnifi.flowType) {
        widgetScriptURL =
          widgetScriptURL + "&flowType=" + syfWidgetObjectUnifi.flowType;
      }
      if (subFlowType !== "") {
        widgetScriptURL = widgetScriptURL + "&subFlowType=" + subFlowType;
      }

      if (syfWidgetObjectUnifi.siteCode) {
        widgetScriptURL =
          widgetScriptURL + "&siteCode=" + syfWidgetObjectUnifi.siteCode;
      }

      if (syfWidgetObjectUnifi.clientTransId) {
        widgetScriptURL =
          widgetScriptURL + "&clientTransId=" + syfWidgetObjectUnifi.clientTransId;
      }

      let script = document.createElement("script");
      script.src = widgetScriptURL;
      document.getElementsByTagName("head")[0].appendChild(script);
    }
  }
}

function isNumber(amount) {
  return /^\d+\.\d+$|^\d+$/.test(amount)
};

export async function mppFromAnyWhere(amount, tags, offerNumber) {
  let initialAmount = "";
  let initialTags = "";
  let mppFromAnyWhereFlag = "Y";
  let siteCode = "";
  let clientTransId = "";

  if (amount) {
    if (isNumber(amount)) {
      initialAmount = amount;
    } else {
      console.warn("Amount should be numeric string")
    }
  }

  if (tags) {
    initialTags = tags;
  }
  let element = document.getElementById("syfMPPScript");
  element = element === null ? getMPPScriptElementOnMissingId() : element;

  if (element != null) {
    let syfWidgetObjectUnifi = getSyfWidgetObject();
    if (
      syfWidgetObjectUnifi.syfPartnerId === "undefined" ||
      syfWidgetObjectUnifi.syfPartnerId == null
    ) {
      console.warn("MPP From Anywhere is disabled. partnerId is mandatory");
    } else {
      if (
        syfWidgetObjectUnifi.syfPartnerId != "undefined" ||
        syfWidgetObjectUnifi.syfPartnerId != null) {
        if (syfWidgetObjectUnifi.initialAmount) {
          if (isNumber(syfWidgetObjectUnifi.initialAmount)) {
            initialAmount = syfWidgetObjectUnifi.initialAmount;
          } else {
            console.warn("Amount should be Numeric String")
          }
        }

        if (syfWidgetObjectUnifi.tags) {
          initialTags = syfWidgetObjectUnifi.tags;
        }
        if (syfWidgetObjectUnifi.offerNumber1) {
            offerNumber = syfWidgetObjectUnifi.offerNumber1;
        }

        if (syfWidgetObjectUnifi.siteCode) {
          siteCode = syfWidgetObjectUnifi.siteCode;
        }

        if (syfWidgetObjectUnifi.clientTransId) {
          clientTransId = syfWidgetObjectUnifi.clientTransId;
        }

        let partnerId = syfWidgetObjectUnifi.syfPartnerId;
        let existingCookie = getSessionCookieAndSyfToken(partnerId);
        const sessionId = setSessionCookie(partnerId);
        let _data = await populateSyfTokenCookie(existingCookie, partnerId)

        if (_data.isSyfTokenCreated) {
          document.cookie = _data.syfToken
          openMPPOfferModal({
            mppFromAnyWhereFlag,
            partnerId: String(partnerId),
            amount: String(initialAmount),
            productCategoryNames: String(initialTags),
            offerNumber: String(offerNumber),
            siteCode,
            clientTransId
          })
        }
      }
    }
  }
}

function validateDBUYInput(inputJSON) {
  if (inputJSON.processInd === "1") {
    const inputValidator = new JSONValidator({ allErrors: true }).compile(
      DBUYModal1Schema
    );
    if (inputValidator(inputJSON)) {
      return true;
    } else {
      console.error("Integration Issue Detected- Modal 1");
      console.error(inputValidator.errors);
      return false;
    }
  }
  if (inputJSON.processInd === "2") {
    const inputValidator = new JSONValidator({ allErrors: true }).compile(
      DBUYModal2Schema
    );
    if (inputValidator(inputJSON)) {
      return true;
    } else {
      console.error("Integration Issue Detected- Modal 2");
      console.error(inputValidator.errors);
      return false;
    }
  }
  if (
    inputJSON.processInd === "3" ||
    inputJSON.processInd === undefined ||
    inputJSON.processInd === null
  ) {
    const inputValidator = new JSONValidator({ allErrors: true }).compile(
      DBUYCombinedModal
    );
    if (inputValidator(inputJSON)) {
      return true;
    } else {
      console.error("Integration Issue Detected- Combined Modal");
      console.error(inputValidator.errors);
      return false;
    }
  }
}
startLazyLoaderMatrix(
  getEnvironmentDetails.bind(null, loadWidgetToDOM)
);

let mppProduct = new Object();

export function callInstallmentProcess(formObj) {
  attachCSSToDOM();
  waitAndExecute(
    window["__syfMPP__"]["matrix"]["UniFi"]["ENV_CONFIG"]["isLoadComplete"],
    callInstallmentProcessImpl.bind(this, formObj)
  );
}
function callInstallmentProcessImpl(formObj) {
  let partnerId = null;
  if (formObj) {
    partnerId = formToJSON(formObj).syfPartnerId;
  }
  const analytics = new Analytics(partnerId);
  mppProduct.activeProduct = new SetPayIntegeration(analytics);
  mppProduct.activeProduct.callInstallmentProcess(formObj);
}
export function calldBuyProcess(formObj, passedJsonObj) {
  attachCSSToDOM();
  waitAndExecute(
    window["__syfMPP__"]["matrix"]["UniFi"]["ENV_CONFIG"]["isLoadComplete"],
    calldBuyProcessImpl.bind(this, formObj, passedJsonObj)
  );
}
function calldBuyProcessImpl(formObj, passedJsonObj) {
  let partnerId = null;
  if (passedJsonObj) {
    partnerId = passedJsonObj.syfPartnerId;
  } else if (formObj) {
    partnerId = formToJSON(formObj).syfPartnerId;
  }
  const analytics = new Analytics(partnerId);
  mppProduct.activeProduct = new DigitalBuyIntegartion(analytics);

  if (passedJsonObj != null) {
    if (validateDBUYInput(passedJsonObj)) {
      mppProduct.activeProduct.calldBuyProcess(formObj, passedJsonObj);
    } else {
      console.error(
        "JSON contains invalid input.Please check  with referece implementation"
      );
    }
  } else if (formObj != null) {
    if (validateDBUYInput(formToJSON(formObj))) {
      mppProduct.activeProduct.calldBuyProcess(formObj, passedJsonObj);
    } else {
      console.error(
        "FORM contains invalid input.Please check with referece implementation"
      );
    }
  }
}
export function openMPPOfferModal(optionalArgs) {
  attachCSSToDOM();
  waitAndExecute(
    window["__syfMPP__"]["matrix"]["UniFi"]["ENV_CONFIG"]["isLoadComplete"],
    openMPPOfferModalImpl.bind(this, optionalArgs)
  );
}
function openMPPOfferModalImpl(optionalArgs) {
  const inputValidator = new JSONValidator({ allErrors: true }).compile(
    OfferInputSchema
  );
  var syfWidgetObject = getSyfWidgetObject();
  optionalArgs["processInd"] = syfWidgetObject.processInd;

  if (optionalArgs["mppFromAnyWhereFlag"] === "Y") {
    optionalArgs["flowType"] = "PDP"
  }
  else if (optionalArgs["isCheckoutLearnClicked"] === true) {
    optionalArgs["flowType"] = "PDP"
  }
  else {
    syfWidgetObject.flowType
      ? optionalArgs["flowType"] = syfWidgetObject.flowType
      : optionalArgs["flowType"] = "PDP";
  }
  if (inputValidator(optionalArgs)) {
    let partnerId = null;
    if (optionalArgs) {
      partnerId = optionalArgs.partnerId;
    }
    const analytics = new Analytics(partnerId);
    mppProduct.activeProduct = new OfferIntegration(analytics);
    mppProduct.activeProduct.openMPPModal(optionalArgs);
  } else {
    console.error("Issue with product integration.");
    console.error(inputValidator.errors);
  }
}
function getSyfWidgetObject() {
  var syfWidgetObjectInUnifi = {};
  try {
    if (
      typeof syfWidgetObject != "undefined" &&
      (syfWidgetObject != "undefined" || syfWidgetObject != null)
    ) {
      syfWidgetObjectInUnifi = syfWidgetObject;
    } else {
      syfWidgetObjectInUnifi = {};
    }
  } catch (e) {
    console.log("syfWidgetObject is not defined" + syfWidgetObject);
  }
  return syfWidgetObjectInUnifi;
}
export default {
  callInstallmentProcess,
  calldBuyProcess,
  openMPPOfferModal,
  mppProduct,
  mppFromAnyWhere,
};


const tagJsonToString = (productAttributesData) => {
  let str = "";
  productAttributesData.cart
    ? str += productAttributesData.cart.total + "##"
    : str += "0##";
  let products = productAttributesData.products
  products.map((prod, index, arr) => {
    str += prod.id + "::"
    prod.price ? str += prod.price + "::" : str += "::";
    prod.description ? str += prod.description + "::" : str += "::";
    prod.productAttributes.map((prodAttribute, index, arr) => {
      if (arr.length - 1 === index) {
        str += prodAttribute.name + "=" + prodAttribute.value;
      } else {
        str += prodAttribute.name + "=" + prodAttribute.value + "||";
      }
      return str
    })
    if (arr.length - 1 === index) {
      str += "";
    } else {
      str += "&&";
    }
    return str;
  })
  console.log("Converted tags :", str);
  let encodedData = window.btoa(str);  // Converting Product Attributes string to encoding
  return encodedData;


}

function addDNSPreFetchInHeader(linkId) {
  if (!document.getElementById(linkId)) {
    var head = document.getElementsByTagName("head")[0];
    var link = document.createElement("link");
    link.id = linkId;
    link.rel = linkId;
    link.href = config[config.env].widgeturl;
    head.appendChild(link);
  }
}