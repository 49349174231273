import config from '../configuration/configuration';
import axios from 'axios';
class Analytics {
    constructor(prop) {
        window._SFDDL = {};
        window._SFDDL.pageInfo = window._SFDDL.pageInfo || {};
        window._SFDDL.formAction = window._SFDDL.formAction || {};
        window._SFDDL.click = window._SFDDL.click || {};
        this.partnerId = prop;
        this.init();
    }
    init() {
        let partnerId = this.partnerId;
        axios.get(config[config.env].getConfigData + "?partnerId=" + partnerId)
            .then((response) => {
                var { analyticsScriptFlag } = response.data;
                if (analyticsScriptFlag === "Y") {
                    let utagScript = document.createElement('script');
                    utagScript.src = config[config.env].adobeAnalyticsUtag;
                    utagScript.id = "syfMPPAnalyticsUtag";
                    document.getElementsByTagName('head')[0].appendChild(utagScript);
                }
            })
            .catch((err) => {
                console.log("Error while loading Analytics scripts")
            });
    }
    destroy() {
        if (document.getElementById("syfMPPAnalyticsUtag") != null) {
            document.getElementById("syfMPPAnalyticsUtag").remove();
        }
    }

    fireAnalyticsEvent(callback) {
        window._SFDDL.click = {
            "action": "syfClickEvent",
            "className": "syfClickEvent",
            "data-object": "button",
            "data-reason": "Close",
            "data-type": "body"
        };
        window._SFDDL.pageInfo = {
            "Country_Language": "US|ENG",
            "SiteIdentifier": "digitalpos",
            "PageFunction": "Global Error Page",
            "PageName": "Global Error Page",
        };
        window._SFDDL.formAction = {
            "appType": "offers_mpp",
            "product": "MPP",
            "action": "Global Error page"
        };
        var event = document.createEvent("Event");
        event = new CustomEvent('syfClickEvent');
        event.initEvent("syfClickEvent");
        dispatchEvent(event);
        callback();
    }



}
export default Analytics;