import axios from 'axios';
import { ResponsiveEmbed } from 'react-bootstrap';
import config from '../configuration/configuration';
import SyfAjax from '../Util/SyfAjax';
export function createInputField(type, name, id, value) {
    var inputField = document.createElement("input");
    inputField.type = type;
    inputField.name = name;
    inputField.id = id;
    inputField.value = value;

    return inputField;
}


export function isNotEmpty(value) {
    return (value !== null && value !== "" && value !== undefined && value !== 'undefined');
}

export function isEmpty(value) {
    return (value == null || value === '' || value === undefined || value === 'Undefined');
}
export function createFormFieldsForJson(formObj, passedJsonObj) {
    if (passedJsonObj != null) {
        formObj = document.createElement("form");
        document.body.appendChild(formObj);
        let inputField = createInputField("hidden", "passedJson",
            "passedJson");
        let successUrl = document.getElementById("successUrl");
        if (isNotEmpty(successUrl)) {
            passedJsonObj.successUrl = successUrl.value;
        }
        let failUrl = document.getElementById("failUrl");
        if (isNotEmpty(failUrl)) {
            passedJsonObj.failUrl = failUrl.value;
        }
        inputField.value = JSON.stringify(passedJsonObj);

        formObj.appendChild(inputField);
        formObj.appendChild(createInputField("hidden", "processInd",
            "processInd", passedJsonObj.processInd));
    }
    return formObj;
}
export function getGenericMPPModalTemplate() {
    let modalTemplate = '<div class="syf-mpp-modal syf-mpp-fade syf-mpp-in" id="mppModal" role="dialog" tabindex="-1" '
        + 'aria-labelledby="MPP Modal">'
        + '<div class="syf-mpp-modal-dialog" role="document">'
        + '<div class="syf-mpp-modal-content"><div class="syf-mpp-chng-height">'
        + '<div id="iframeMPPloading" class="syf-mpp-loading">'
        + '<img id="syfMPPLoadingImage" alt="loading" class="syf-mpp-loading-image"/></div>'
        + '<div class="syfMPPClose-bt-full"  id="syf-mpp-close-btn-new">'
        + '<div tabindex="0" id="syfMPPCrossButton"'
        + '	class="syf-mpp-close" aria-label="Close"'
        + '	onclick="syfMPP.mppProduct.activeProduct.closeModal()" onkeypress="syfMPP.mppProduct.activeProduct.closeModalWithKeyboard(event)"'
        + '	>'
        + '	<img alt="close" id="syfMPPModalCloseBtn"  src="'
        + config[config.env].syfXIamageUL
        + '" class="syf-mpp-modal-XImage">'
        + '</div>'
        + '</div>'
        + '<iframe id="mppFrame" name="mppFrame" title="SYF MultiProduct Platform" frameborder="0px" style="width:100%;height:100%;border:none;"></iframe></div></div></div></div>';
    return modalTemplate;
}

export function formToJSON(form) {
    // Define JSON object structure so that Merchant can view it
    let JSONObject = {};
    let elements = form.elements;
    [...elements].forEach(element => {
        JSONObject[element.id] = element.value;
    });
    return JSONObject;
}
export function isEventSourceSYF(eventSource) {
    let allowedDomains = ["syf.com", "mysynchrony.com", "syfpos.com", "syfpayments.com","setpay.com", "syfbank.com"];
    for (let i = 0; i < allowedDomains.length; i++) {
        if (eventSource.includes(allowedDomains[i])) {
            return true;
        }
    }
    return false;
}

export function getSeamlessApplyBuyCookie(partnerId, callback) {
    let seamlessApplyBuyCookieValue;
    let nameEQ = "syfToken" + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ')
            c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) {
            let token = c.substring(nameEQ.length, c.length);
            if (token.includes(partnerId)) seamlessApplyBuyCookieValue = token;
        }
    }
    //cookie not found
    if(partnerId!==null && seamlessApplyBuyCookieValue===undefined){
        let syfAjax = new SyfAjax();
        syfAjax.get(config[config.env].widgeturl+"/syftoken?partnerId="+partnerId, "", (err, data) => {
                if (err || data.status === 400 || data.status === 404) {
                    let logDesc = err;
                    console.error(logDesc);
                    if (data.status === 400 || data.status === 404) {
                        logDesc = "Failed 400 " + data.responseURL;
                    }
                    console.error(logDesc);
                    this.syfUILogger.sendLogs(logDesc);
                }else{
                    data=JSON.parse(data.responseText);
                    let expDate = new Date();
                    expDate.setHours(expDate.getHours() + 2);
                    let expires = "expires=" + expDate.toGMTString();
                    let cookieContent="syfToken" + "=" +data['syf-token']+ ";"+expires+";path=/;Secure";
                    document.cookie =cookieContent;
                    callback(data['syf-token']) ;
                }

            syfAjax = null;
        });
    } else {
        callback(seamlessApplyBuyCookieValue);
    }

}

export function getMPPScriptElementOnMissingId() {
    console.warn("Mandatory id Attribute in script tag is missing.Fallback Mode Activated.");
    let scripts = document.getElementsByTagName('script');
    let scriptElement = null;
    for (let i = 0; i < scripts.length; i++) {
        if (scripts[i].src.indexOf('UniFi.js') >= 0) {
            scriptElement = scripts[i];
        }
    }
    return scriptElement;
}
export function startLazyLoaderMatrix(callback) {
    window['__syfMPP__'] = {};
    window['__syfMPP__']['matrix'] = { 'UniFi': { 'ENV_CONFIG': { 'start': '', 'end': '', 'isLoadComplete': false } }, 'WIDGET': {} };
    callback();
}
export function waitAndExecute(isSatisfied, callback) {
    let waitTime = 5;// unit: ms
    window.setTimeout(() => {
        if (isSatisfied) {
            callback();
        } else {
            waitAndExecute(isSatisfied, callback);
        }
    }, waitTime);

}
export function generateGALinkerId(url) {

    return url.toString();

}

export const getSessionCookie = () =>{
    return window.sessionStorage.getItem('syf-session');
}

export const setSessionCookie = (id) =>{
    let sessionId = getSessionCookie();
    const randomArray = new Uint32Array(1);
    window.crypto.getRandomValues(randomArray);
    if (sessionId === null) {
        sessionId = id ? (id + "").substring((id + "").length - 7) : "";
        sessionId += (new Date()).getTime() + (randomArray[0] + "").substring(2, 8);
        window.sessionStorage.setItem('syf-session', sessionId);
    }
    return sessionId;
}

export function getSessionCookieAndSyfToken(partnerId){
    let nameEQ = "syfToken" + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) {
            let token = c.substring(nameEQ.length, c.length);
            if (token.includes(partnerId)) return token;
        }
    }
    return "MPP";
}

export async function populateSyfTokenCookie(existingCookie, partnerId){
    if(existingCookie === "MPP" || existingCookie===undefined || existingCookie.indexOf(partnerId)<0){
        let cookieData = await setCookie(partnerId);
        return cookieData;
    }else{
        var expDate = new Date();
        expDate.setHours(expDate.getHours() + 2);
        var expires = "expires=" + expDate.toGMTString();
        let cookieContent = "syfToken" + "=" + existingCookie + ";" + expires + ";path=/;";
        return {
            isSyfTokenCreated: true,
            syfToken: cookieContent
        }
    }
}

const setCookie = async (partnerId) => {
    var expDate = new Date();
    expDate.setHours(expDate.getHours() + 2);
    var expires = "expires=" + expDate.toGMTString();

    var data = await axios.get(config[config.env].widgeturl+"/syftoken?partnerId="+partnerId)
    .then((response) => response.data)
      .then((data) => {
        let cookieContent = "syfToken" + "=" + data["syf-token"] + ";" + expires + ";path=/;";
        return cookieContent
      })

    return {
        isSyfTokenCreated: true,
        syfToken: data
    }
}

export default { isEmpty, isNotEmpty, createInputField, createFormFieldsForJson, getGenericMPPModalTemplate, formToJSON, isEventSourceSYF, getSeamlessApplyBuyCookie, generateGALinkerId, getMPPScriptElementOnMissingId, startLazyLoaderMatrix, waitAndExecute, populateSyfTokenCookie, getSessionCookieAndSyfToken };
